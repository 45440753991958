import "./styles.scss";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import sajhaImg from "../../asserts/Footer/jharkhand logo 1 (1).png"
import jk_img from "../../asserts/Footer/Jharkhand Sports Directorate logo_white 2.png"
import LoginLeft from "../../components/LoginLeft";

function LoginwithOTP_V2() {
  const navigate = useNavigate();
  const { handleSubmit, register } = useForm();
  const onSubmit = (e) => console.log("data", e);

  return (
    <div className="sign_in_right_container">
      <div className="loginLeft">
        <LoginLeft />
        <div className="jharkhand_logos_login">
          <div>
            <Link to="/">
              <img src={jk_img} width="80" />
            </Link>
          </div>
          <div>
            <Link to="/">
              <img src={sajhaImg} width="75" />
            </Link>
          </div>
        </div>
      </div>

      <div className="loginRight loginRightv2">
        <h1>Log In</h1>
        <p className="px-5 mb-4">Enter your mobile number to access your account</p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            placeholder="Mobile Number"
            id=" phnumber"
            className="phoneNumber_otp"
            {...register("phnumber")}
          />

          <button className="submit-btn" type="submit">
            Generate OTP
          </button>

          <p className="otp_page_  mt-3" onClick={() => navigate("/")}>
            Return to Home Page
          </p>
        </form>
      </div>
    </div>
  );
}

export default LoginwithOTP_V2;
