import styles from "./styles.module.scss";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import search from "../../asserts/E-tender/search.png";
import pdf from "../../asserts/E-tender/pdf.png";
import greenPdf from "../../asserts/E-tender/green_pdf.png";
import {
  getAllEtenderWebsiteNotification,
  getAllEtenderWebsiteNotificationBySearch,
} from "../../services/EtenderApiService/EtenderApiService";
import ReactPaginate from "react-paginate";
import { getFilePreview } from "../../utils/filePreviewHelper";
import { useParams } from "react-router-dom";

function Index({id}) {
 console.log("idtrander==>",id)
  const [hoveredItemId, setHoveredItemId] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [totalpage, setTotalpage] = React.useState(null);
  const { hash } = useLocation();
  const [searchInputValue, setsearchInputValue] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [bannerTitle, setBannerTitle] = React.useState("");
  console.log("bannerTitle",bannerTitle);

  const [perPage, setPerPage] = useState(10);
 
 



  React.useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.replace("#", ""));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function fetchData(id) {
    try {
      let res = await getAllEtenderWebsiteNotification(id, currentPage);
      setTotalpage(res?.data?.data?.total)
      setData(() =>
        res?.data?.data?.data?.filter((ele) => ele.type !== "tender")
      );
    } catch (error) {
      console.log(error);
    }
  }
  React.useEffect(() => {
    fetchData(id);
  }, [currentPage]);

  const handleFetchSearchData = async () => {
    try {
      if (searchInputValue) {
        let res = await getAllEtenderWebsiteNotificationBySearch({
          search: searchInputValue,
          type_id: id,
        });
        // console.log("searchResponse===>",res.data.data.total);
        setTotalpage(res.data.data.total)
        setData(() =>
          res?.data?.data?.data?.filter((ele) => ele.type !== "tender")
        );
      } else {
        fetchData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageChange = (event) => {
    const selectedPage = event.selected + 1;
    setCurrentPage(selectedPage); // React Paginate provides `selected` in the event object
  };

  useEffect(() => {
    if (id === "1") {
      setBannerTitle("TENDER");
    } else if (id === "7") {
      setBannerTitle("Policies");
    } else if (id === "8") {
      setBannerTitle("Govt Circular");
    } else {
      setBannerTitle(""); // Default case, if id doesn't match any condition
    }
  }, [id]);



  return (
    <div>
      <div className="etender-container">
        <div className="etender-container">
          <div className="text-center">
            <p className="text-white e-tender-head">{bannerTitle}</p>
          </div>
          <div className="e-tender-search-field">
            <div>
              <input
                placeholder="Search..."
                className="input"
                onChange={(e) => setsearchInputValue(e.target.value)}
              />
            </div>
            <div>
              <img
                width="100"
                src={search}
                onClick={handleFetchSearchData}
                alt="search"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="e-tender-table-section" id="E-Tender">
        <div className="e-tender-table-content">
          <div className="table2" id="jharkhand-e-tender-table">
            <div
              className={`${`E-tender-fopTableHeader`} position-relative py-4 body-text`}
            >
              <div>S. No</div>
              <div>TITLE</div>
              <div>Description</div>
              <div>Start Date/Time</div>
              <div>End Date/Time</div>
              <div>Documents</div>
            </div>
            <div className="bodyContainer">
              {data && data.length > 0 ? (
                data.map((item, index) => (
                  <div
                    className={`E-tender-fopDivContainer ${hoveredItemId === item.id ? "hovered" : ""
                      }`}
                    key={index}
                    onMouseEnter={() => setHoveredItemId(item.id)}
                    onMouseLeave={() => setHoveredItemId(null)}
                  >
                    <td>{index + 1 + (currentPage - 1) * perPage}</td>
                    <div className="title_width">{item?.title}</div>
                    <div className="message-container">{item?.description}</div>
                    <div className="m-4">
                      {item?.start_date_time
                        ? new Date(item?.start_date_time).toLocaleString("en-us", {
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                        })
                        : "N/A"}
                    </div>
                    <div className="m-4">
                      {item?.end_date_time
                        ? new Date(item?.end_date_time).toLocaleString("en-us", {
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                        })
                        : "N/A"}
                    </div>
                    <div className="m-4">
                      {/* <img
          src={hoveredItemId === item.id ? greenPdf : pdf}
          alt="PDF Icon"
          width="30"
          height="auto"
        /> */}
                      {getFilePreview(item.attachement_storage_url)}
                    </div>
                  </div>
                ))
              ) : (
                <div className="no-data-found text-center">No data found</div>
              )}

            </div>
          </div>
        </div>

        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={Math.ceil(totalpage / perPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          activeClassName={"active"}
          previousClassName={"prev-button"}
          nextClassName={"next-button"}
          disabledClassName={"disabled"}
        />
      </div>
    </div>
  );
}

export default Index;
