export const GET_COUNTRY_URL = "/api/country";
export const GET_STATE_URL = "/api/state/";
export const GET_CITY_URL = "/api/city/";
export const REGISTER_USER = "/registration/createRegistration";
export const UPDATE_FIELDS_AT_EVERY_STEP = "/registration/update";
export const GET_REGISTRATION_LISTING = "registration/getRegisteredUserById/";
export const GET_CORRECTION_DATA = "/registration/getCorrection/1";

// get all website notification

export const GET_ALL_WEBSITE_NOTIFICATION = "api/getAllWebsiteNotification";

// get all etender-website-notification

export const GET_ALL_E_TENDER_NOTIFICATION = "api/getAllEventDataByType";
export const GET_ALL_SEARCH_WEBSITE_NOTIFICATION = "api/getAllWebsiteNotificationForWebsite";
export const GET_ALL_SEARCH_GALLERY = "api/media/all";

// media
export const GET_MEDIA =
  "https://media-api.jharkhandsports.in/api/news/all?limit=1000";
