import styles from "./styles.module.css";
import ReactPlayer from "react-player";
import useWindowDimensions from "../../utils/windowSize";
import classNames from "classnames";
import { useEffect, useState } from "react";
// import LoadingCircle from "components/common/loadingCircle";
import  LoadingCircle from "../../registrationServices/registrationComponent/Loader/Loader";

// import { fetchVideos } from "api/mediaApiService";
import dayjs from "dayjs";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import axios from "axios";
import LatestBanner from "../LatestNews/latestBanner/LatestBanner";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1600 },
    items: 3,
    paritialVisibilityGutter: 60,
  },
  tablet: {
    breakpoint: { max: 1600, min: 990 },
    items: 3,
    paritialVisibilityGutter: 50,
  },
  mobile: {
    breakpoint: { max: 990, min: 800 },
    items: 2,
    paritialVisibilityGutter: 30,
  },
  small: {
    breakpoint: { max: 800, min: 0 },
    items: 1,
    paritialVisibilityGutter: 30,
  },
};

function LatestVideo() {
  const { width } = useWindowDimensions();

  const [data, setData] = useState([]);
  console.log("data===>", data);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchList = async (page = 1) => {
      setLoading(true);
      // const { data: result } = await fetchVideos(page);
      const result = axios
        .get(`https://media-api.jharkhandsports.in/api/video/all?limit=1000`)

        .then((res) => {
          console.log(res?.data?.records);
          setData(res?.data?.records);
          return res?.data?.records;
        })
        .catch((err) => console.log);
      if (result?.records) {
        setTotalPages(result?.totalPages);
        const obj = result?.records?.reduce(
          (acc, item) => {
            if (item?.status !== "ENABLED") {
              return acc;
            }
            if (acc[item.title]) {
              return {
                ...acc,
                [item.title]: [
                  ...acc[item.title],
                  { ...item, link: item?.link.split("&")[0] },
                ],
              };
            }
            return {
              ...acc,
              [item.title]: [{ ...item, link: item?.link.split("&")[0] }],
            };
          },
          { ...data }
        );
        setData(obj);
      }
      setLoading(false);
    };
    fetchList(page);
  }, [page]);
  console.log(data);
  return (
    <div
      className={`postion_rel ${styles?.videoContainer}`}
      style={{
        background: "#fafafa",
      }}
    >
      {/* {width > 639 && <AboutusHeader firstText="Latest" secondText="VIDEO" />} */}
      <LatestBanner />
      {data && data.length > 0 ? (
          <div>
          <div
            style={{
              // background: "#19417A",
              position: "absolute",
              width: "100%",
              height: "20rem",
              zIndex: 0,
            }}
          />
          <div
            // className={
            //   width < 640
            //     ? "flex flex-column-reverse w-100 p-2 mt-4"
            //     : "grid grid-cols-8 mt-5"
            // }
            style={{ display: "flex", alignItems: "center" }}
          >
            <div
              className="z-1 p-3 col-span-2 "
              style={{
                background: "white",
                margin: width < 640 ? "0" : "1rem 0 0 1rem",
                height: width < 640 ? "auto" : "20rem",
                position: width < 640 ? "relative" : "relative",
                zIndex: width < 640 ? 10 : 0,
                boxShadow: width < 640 ? "1px 4px 10px 1px gray" : "none",
                bottom: width < 640 ? "2.7rem" : "",
                width: width < 640 ? "85%" : "100%",
                flex: 1,
              }}
            >
              <p className={styles?.videoCeremonyTag}>CEREMONY</p>
              <div className="m-0">
                <p className="text-sm bold" style={{ color: "#797676" }}>
                {dayjs(data[0]?.createdAt).format("ddd DD MMM YY")}
                </p>
                <h2
                  className="bold"
                  style={{
                    fontSize: width < 640 ? "18px" : "26px",
                    color: "#313131",
                    fontFamily: "Lato",
                  }}
                >
                  {data[0]?.title}
                </h2>
              </div>
            </div>
            <div
              style={{
                flex: 3,
              }}
              className="col-span-6 z-1 w-100"
            >
              <div
                className={styles?.videoGradient}
                style={{
                  padding: "1.5rem",
                  borderRadius: "20px",
                  margin: "1.2rem 0",
                  width: "100%",
                  display: "grid",
                  placeItems: "center",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                {/* <ReactPlayer
                  url="https://youtu.be/dExyOYssg_Q"
                  width="98%"
                  height="95%"
                  borderRadius="12px"
                  aria-hidden="true"
                /> */}
                <div
                  style={{
                    borderRadius: "24px",
                    overflow: "hidden",
                    width: "98%",
                    height: "95%",
                  }}
                >
                  <ReactPlayer
                    url={data[0]?.link}
                    width="100%"
                    height="100%"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
          </div>
          {loading && <LoadingCircle />}
          {/* <div className={classNames("mb-4", width < 640 ? "" : "mt-5 px-5")}>
            {data && Object.keys(data)?.length
              ? Object.entries(data)?.map((item, id) => (
                  <div key={id + "-content"} className="cursor-pointer my-5">
                    <h2
                      className="text-2xl bold mb-4"
                      style={{
                        color: "#313131",
                        marginLeft:
                          width < 640 || item[1].length <= 3 ? "1rem" : "4rem",
                      }}
                    >
                      {item[0]}
                    </h2>
                    <div className="flex justify-content-between align-items-center"></div>
                    <div style={{ margin: width < 640 ? "0px 20px" : "0px" }}>
                      <Carousel responsive={responsive}>
                        {item[1]?.map((video, id) => (
                          <div key={id}>
                            <ReactPlayer
                              url={video?.link}
                              key={video?.link + id}
                              width={width < 640 ? "300px" : "400px"}
                              aria-hidden="true"
                            />
                            <div
                              className={classNames(
                                "position-relative py-2",
                                width < 640 ? "px-2" : "px-4"
                              )}
                              style={{
                                background: width < 640 ? "#343333" : "#e8e7e7",
                                width: width < 640 ? "300px" : "400px",
                              }}
                            >
                              <div className="flex gap-1 align-items-center gap-2">
                                <p
                                  className={`text-sm ${styles?.videoCeremonyTag}`}
                                  style={{ background: "white" }}
                                >
                                  HIGHLIGHT
                                </p>
                                <p
                                  className={classNames("bold text-xs")}
                                  style={{
                                    color: width < 640 ? "text-white" : "#696868",
                                  }}
                                >
                                  {dayjs(video?.createdAt).format(
                                    "ddd DD MMM YY"
                                  )}
                                </p>
                              </div>
                              <div
                                className={classNames(
                                  "bold",
                                  width < 640 ? "text-white line-clamp-2" : ""
                                )}
                              >
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: video?.description,
                                  }}
                                ></p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </Carousel>
                    </div>
                  </div>
                ))
              : null}
            {data?.length !== 0 && page < totalPages ? (
              <div
                className={classNames(
                  "flex justify-content-center align-items-center",
                  width < 640 ? "hidden" : ""
                )}
              >
                <div
                  className="p-2 px-4 cursor-pointer"
                  style={{
                    background:
                      "var(--hhhhh, linear-gradient(90deg, #19417A 0%, #0B9444 100%))",
  
                    color: "white",
                    borderRadius: "20px",
                  }}
                  onClick={() => setPage(page + 1)}
                >
                  Load More
                </div>
              </div>
            ) : null}
          </div> */}
  
          <div className={classNames("mb-4", width < 640 ? "" : "mt-5 px-5")}>
            {/* {data && Object.keys(data)?.length
              ? Object.entries(data)?.map((item, id) => (
                 
                ))
              : null} */}
  
            <Carousel responsive={responsive}>
              {data?.map((video, id) => (
                <div key={id}>
                  <ReactPlayer
                    url={video?.link}
                    key={video?.link + id}
                    width={width < 640 ? "300px" : "400px"}
                    aria-hidden="true"
                  />
                  <div
                    className={classNames(
                      "position-relative py-2",
                      width < 640 ? "px-2" : "px-4"
                    )}
                    style={{
                      background: width < 640 ? "#343333" : "#e8e7e7",
                      width: width < 640 ? "300px" : "400px",
                    }}
                  >
                    <div className=" gap-1 align-items-center gap-2">
                      <div
                        className={`text-sm ${styles?.videoCeremonyTag}`}
                        style={{ background: "white" }}
                      >
                        HIGHLIGHT
                      </div>
                      <p
                        className={classNames("bold text-xs")}
                        style={{
                          color: width < 640 ? "text-white" : "#696868",
                        }}
                      >
                        {dayjs(video?.createdAt).format("ddd DD MMM YY")}
                      </p>
                    </div>
                    <div
                      className={classNames(
                        "bold",
                        width < 640 ? "text-white line-clamp-2" : ""
                      )}
                    >
                      <p
                        dangerouslySetInnerHTML={{
                          __html: video?.description,
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
            {/* {data.map((item) => {
              console.log("item==>", item, item.id);
              return (
                <>
                  <div className="cursor-pointer my-5">
                    <h2
                      className="text-2xl bold mb-4"
                      style={{
                        color: "#313131",
                        marginLeft:
                          width < 640 || item[1]?.length <= 3 ? "1rem" : "4rem",
                      }}
                    >
                      Videos
                    </h2>
                    <div className="flex justify-content-between align-items-center"></div>
                    <div style={{ margin: width < 640 ? "0px 20px" : "0px" }}>
                   
                    </div>
                  </div>
                </>
              );
            })} */}
  
            {/* <div key={id + "-content"} className="cursor-pointer my-5">
                    <h2
                      className="text-2xl bold mb-4"
                      style={{
                        color: "#313131",
                        marginLeft:
                          width < 640 || item[1].length <= 3 ? "1rem" : "4rem",
                      }}
                    >
                      {item[0]}
                    </h2>
                    <div className="flex justify-content-between align-items-center"></div>
                    <div style={{ margin: width < 640 ? "0px 20px" : "0px" }}>
                      <Carousel responsive={responsive}>
                        {item[1]?.map((video, id) => (
                          <div key={id}>
                            <ReactPlayer
                              url={video?.link}
                              key={video?.link + id}
                              width={width < 640 ? "300px" : "400px"}
                              aria-hidden="true"
                            />
                            <div
                              className={classNames(
                                "position-relative py-2",
                                width < 640 ? "px-2" : "px-4"
                              )}
                              style={{
                                background: width < 640 ? "#343333" : "#e8e7e7",
                                width: width < 640 ? "300px" : "400px",
                              }}
                            >
                              <div className="flex gap-1 align-items-center gap-2">
                                <p
                                  className={`text-sm ${styles?.videoCeremonyTag}`}
                                  style={{ background: "white" }}
                                >
                                  HIGHLIGHT
                                </p>
                                <p
                                  className={classNames("bold text-xs")}
                                  style={{
                                    color: width < 640 ? "text-white" : "#696868",
                                  }}
                                >
                                  {dayjs(video?.createdAt).format(
                                    "ddd DD MMM YY"
                                  )}
                                </p>
                              </div>
                              <div
                                className={classNames(
                                  "bold",
                                  width < 640 ? "text-white line-clamp-2" : ""
                                )}
                              >
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: video?.description,
                                  }}
                                ></p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </Carousel>
                    </div>
                  </div> */}
            {/* {data?.length !== 0 && page < totalPages ? (
              <div
                className={classNames(
                  "flex justify-content-center align-items-center",
                  width < 640 ? "hidden" : ""
                )}
              >
                <div
                  className="p-2 px-4 cursor-pointer"
                  style={{
                    background:
                      "var(--hhhhh, linear-gradient(90deg, #19417A 0%, #0B9444 100%))",
  
                    color: "white",
                    borderRadius: "20px",
                  }}
                  onClick={() => setPage(page + 1)}
                >
                  Load More
                </div>
              </div>
            ) : null} */}
          </div>
        </div>

      ): (
        <div className="no-data-container text-center py-4">
            <h3 className="data_not_fount_title">Video not available at the moment...</h3>
          </div>
      )}
    
    </div>
  );
}

export default LatestVideo;
