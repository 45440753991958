import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Header from "../../components/Header";
import "./styles.scss";
import runningImg from "../../asserts/NewsDetails/runningBg.png";
import templeBG from "../../asserts/NewsDetails/TempleBg.png";
import twitter from "../../asserts/NewsDetails/Icon awesome-twitter-square.png"
import facebook from "../../asserts/NewsDetails/Icon metro-facebook.png";
import link from "../../asserts/NewsDetails/Icon awesome-link.png"
import Slider from "../../components/LatestSlider/Index";
import { Container, Row, Col, Card } from "react-bootstrap";
import { formatDateTime } from "../../utils/formatDateTime";

const newsData = [
  {
    image: runningImg,
    button: "NEWS",
    heading:
      "Uttarakhand khel Mahakumbh begins, athlete who breaks national record will get a reward of Rs 1 lakh",
    date: "MON 19 JUNE 2023",
  },
  {
    image: runningImg,
    button: "NEWS",
    heading:
      "Uttarakhand khel Mahakumbh begins, athlete who breaks national record will get a reward of Rs 1 lakh",
    date: "MON 19 JUNE 2023",
  },
  {
    image: runningImg,
    button: "NEWS",
    heading:
      "Uttarakhand khel Mahakumbh begins, athlete who breaks national record will get a reward of Rs 1 lakh",
    date: "MON 19 JUNE 2023",
  },
  {
    image: runningImg,
    button: "NEWS",
    heading:
      "Uttarakhand khel Mahakumbh begins, athlete who breaks national record will get a reward of Rs 1 lakh",
    date: "MON 19 JUNE 2023",
  },
];

const truncateText = (htmlContent, wordLimit = 50) => {
  // Create a temporary element to strip HTML tags and count words
  const tempElement = document.createElement("div");
  tempElement.innerHTML = htmlContent;
  const plainText = tempElement.textContent || tempElement.innerText || "";

  // Split the text by spaces to limit the word count
  const words = plainText.split(" ");
  const truncatedText = words.slice(0, wordLimit).join(" ");

  // Add ellipsis if the text has more than the word limit
  return words.length > wordLimit ? `${truncatedText}...` : truncatedText;
};

const NewsDetails = () => {
  const navigate = useNavigate();
  const [news, setNews] = useState([]);
  const [newsDetails, setNewsDetails] = useState([]);
  console.log("newsDetails==>",newsDetails)
  const { id } = useParams();
 

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  useEffect(() => {
    // Fetch news data from the API
    const fetchNews = async () => {
      try {
        const response = await axios.get(
          "https://media-api.jharkhandsports.in/api/news/all"
        );
        setNews(response.data.records);
        
        // Find the news item with the matching 'id' and save it in 'newsDetails'
        // const selectedNews = response.data.records.find(
          
        //   (item) => item.id === Number(id)
        // );
        const selectedNews = response.data.records.find((item) => {
          console.log("Item ID:", item.id, "Number(ID):", Number(id));
          return item.id === Number(id);
        })
        
        setNewsDetails(selectedNews);
      } catch (error) {
        console.error("Error fetching news data:", error);
      }
    };

    fetchNews();
  }, [id]);

  
  return (
    <div>
    <div className="linear-gradient"></div>
    <div className="news-details-banner">
      {/* <Header /> */}
      <div className="container">
        <div className="heading-text text-center">
          <p className="text-white news-details-heading">NEWS DETAILS</p>
        </div>
      </div>
    </div>
    <div className="news-details-container">
      <div className="left-section-News">
        <div className="news-room-image">
          <p> JHARKHADN </p>
          <h1>NEWS ROOM</h1>
        </div>
        <div className="news-list-container">
          <div className="heading">
            {" "}
            <h3> Previous New Stories </h3>{" "}
          </div>
          <div className="news-list">
            {news.map((item, index) => {
              return (
                <div item={index} className="news-list-card" onClick={() => navigate(`/latest-news/${item.id}`)}>
                  <div className="img-container">
                    <img
                      src={`https://media-api.jharkhandsports.in/news/${item.newsImage}`}
                      alt=""
                    />
                  </div>
                  <div className="card-text-container">
                    <p className="button-news"> NEWS </p>
                    <p>
                      {" "}
                      <b> {item?.title} </b>{" "}
                    </p>
                    <p className="card-date-news-details"> {item?.date} </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="right-section-News">
        <div>
          <img
            src={`https://media-api.jharkhandsports.in/news/${newsDetails?.newsImage}`}
            width="700"
          />
        </div>
        <div className="right-news-detail-container">
          <span>NEWS</span>
          <h3>{newsDetails?.title}</h3>
          <p className="date"> {formatDateTime(newsDetails?.createdAt)}</p>
          <p
            className="mapd-desc"
            dangerouslySetInnerHTML={{
              __html: newsDetails?.description,
            }}
          ></p>
          <div className="article-details">
            <div className="article-left">
              Enjoy this article? Why not share…
            </div>
            <div>
              <img src={twitter} width="20" />
            </div>
            <div>
              <img src={facebook} width="20" />
            </div>
            <div>
              <img src={link} width="20" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default NewsDetails;
