import pdf from "../asserts/E-tender/pdf.png"; // Adjust the path to your actual PDF icon file

export const getFilePreview = (fileUrl) => {
  if (!fileUrl) return <span>N/A</span>;

  // Check if the file is a PDF
  const isPdf = (url) => /\.(pdf)(\?.*)?$/i.test(url);

  // Check if the file is an image
  const isImage = (url) =>
    /\.(jpg|jpeg|png|gif|bmp|webp|svg)(\?.*)?$/i.test(url);

  if (isPdf(fileUrl)) {
    // Return PDF preview
    return (
      <div
        onClick={() => window.open(fileUrl, "_blank")}
        style={{ cursor: "pointer" }}
      >
        <img src={pdf} alt="Open PDF file" width="30" height="auto" />
      </div>
    );
  } else if (isImage(fileUrl)) {
    // Return Image preview
    return (
      <div
        onClick={() => window.open(fileUrl, "_blank")}
        style={{ cursor: "pointer" }}
      >
        <img src={fileUrl} alt="Open image file" width="100" height="auto" />
      </div>
    );
  } else {
    // Return fallback for unsupported files
    return <span>Unsupported file type</span>;
  }
};