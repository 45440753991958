
import "./styles.scss";
import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import Header from "../../components/Header";
import search from "../../asserts/E-tender/search.png";
import pdf from "../../asserts/E-tender/pdf.png";
import greenPdf from "../../asserts/E-tender/green_pdf.png";
import table_bg from "../../asserts/E-tender/tableBG.png";
import Slider from "../../components/SwipperSlider/index";
// * Core UI Components
import { CButton, CFormSelect } from "@coreui/react";
//

// api calling function
import { getAllWebsiteNotification, getAllWebsiteNotificationBySearch } from "../../services/notificationForWebsite/notificationForWebsite";
import { getFilePreview } from "../../utils/filePreviewHelper";
import ReactPaginate from "react-paginate";

function Index() {
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const { hash } = useLocation();
  const [data, setData] = useState([]);
  const [searchValue, setsearchValue] = useState("");
  const debounceTimeout = useRef(null);
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState({});
  const [searchInputValue, setsearchInputValue] = React.useState("");
  const [value, onChange] = useState([]);
  const [totalpage, setTotalpage] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [perPage, setPerPage] = useState(10);

  const toggle = () => setOpen(!open);
  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.replace("#", ""));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //
  async function fetchData(formData = {}) {
    try {
      let res = await getAllWebsiteNotification(formData, currentPage);
      setTotalpage(res?.data?.data?.total)
      setData(res?.data?.data?.data);
    } catch (error) {
      console.log(error);
    }
  }
  //
  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const debounceSearch = (value) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(async () => {
      let formData = new FormData();
      formData.append("title", value);
      await fetchData(formData);
    }, 500);
  };
  const handleChange = (e) => {
    setsearchValue(e.target.value);
    debounceSearch(e.target.value);
  };

  const handleFetchSearchData = async () => {
    try {
      if (searchInputValue) {
        let res = await getAllWebsiteNotificationBySearch({
          search: searchInputValue,

        });
       
        setTotalpage(res.data.data.total)
        setData(() =>
          res?.data?.data?.data?.filter((ele) => ele.type !== "tender")
        );
      } else {
        fetchData();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handlePageChange = (event) => {
    const selectedPage = event.selected + 1;
    setCurrentPage(selectedPage); // React Paginate provides `selected` in the event object
  };


  return (
    <div>
      <div className="etender-container">
        <div className="etender-container">
          <div className="text-center">
            <p className="text-white e-tender-head">WEBSITE NOTIFICATION</p>
          </div>
          <div className="e-tender-search-field">
            <div>
              <input
                placeholder="Search..."
                className="input"
                onChange={(e) => setsearchInputValue(e.target.value)}
              />
            </div>
            <div>
              <img
                width="100"
                src={search}
                onClick={handleFetchSearchData}
                alt="search"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="e-tender-table-section" id="E-Tender">
        <div className="e-tender-table-content">
          <div className="table2" id="jharkhand-e-tender-table">
            <div
              className={`${`E-tender-fopTableHeader`} position-relative py-4 body-text`}
            >
              <div>S. No</div>
              <div>Title</div>
              <div>Message</div>
              <div>URL</div>
              <div>Documents</div>
              <div>Created On</div>
            </div>
            <div className="bodyContainer">
              {data.length > 0 ? (
                data.map((item, index) => (
                  item?.notification_status === "1" && (
                    <div
                      className={`E-tender-fopDivContainer ${hoveredItemId === item.id ? "hovered" : ""}`}
                      key={index}
                      onMouseEnter={() => setHoveredItemId(item.id)}
                      onMouseLeave={() => setHoveredItemId(null)}
                    >
                      <td>{index + 1 + (currentPage - 1) * perPage}</td>
                      <div className="title_width">{item?.title}</div>
                      <div className="message-container">{item?.message}</div>
                      <div className="m-4">
                        {item?.url ? (
                          <Link className="text-decoration-none" to={item.url}>
                            URL
                          </Link>
                        ) : (
                          <span>N/A</span>
                        )}
                      </div>
                      <div className="m-4">
                        {getFilePreview(item.attachment_url)}
                      </div>
                      <div>
                        {new Date(item?.created_at).toLocaleString("en-us", {
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                        })}
                      </div>
                    </div>
                  )
                ))
              ) : (
                <div className="text-center my-4">No data found</div>
              )}


            </div>
          </div>
        </div>

        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={Math.ceil(totalpage / perPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          activeClassName={"active"}
          previousClassName={"prev-button"}
          nextClassName={"next-button"}
          disabledClassName={"disabled"}
        />
      </div>
    </div>
  );
}

export default Index;
