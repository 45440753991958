import "./styles.scss";
import { useState, useRef, useEffect, useContext } from "react";

import dummyImg from "../../../../asserts/Registration/dummyImg.jpg";
import uploadBtn from "../../../../asserts/Registration/uploadBtn.png";
import { UploadDocument } from "../../../apiServices/educationApi";
import {
  CFormSelect,
  CFormInput,
  CButton,
  CInputGroupText,
  CInputGroup,
  CFormText,
} from "@coreui/react";
import Swal from "sweetalert2";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import DataContext from "../../../../helpers/UserDataContext";

import { VerticalStepperContext } from "../../../../Context/VerticalStepperCount/VerticalStepper";
function PersonalRegisterDetails() {
  // const { userData, setUserData } = useContext(DataContext);
  const {
    userData,
    setUserData,
    userDocuments,
    setUserDocuments,
    correctionData,
    currentStep,
    db_step_regestration,
    isPasswordSet,
    setIsPasswordSet,
    isPasswordClose
  } = useContext(VerticalStepperContext);
  const [documentName, setDocumentName] = useState("tenth");
  const [showPassword, setShowPassword] = useState(false);
  const [pincodeError, setPincodeError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [imageSrc, setImageSrc] = useState(dummyImg);
  const user = JSON.parse(localStorage.getItem("user"));
  const today_date = new Date().toISOString().split("T")[0];
  const [isDisabledButton, setIsDisabledButton] = useState({
    tenth: true,
    twelth: true,
    ug: true,
    pg: true,
    phd: true,
    other: true,
    profile_image: true,
  });
  console.log("current step from coontext", currentStep);
  const [isLoadingUpload, setIsLoadingUpload] = useState({
    tenth: false,
    twelth: false,
    ug: false,
    pg: false,
    phd: false,
    other: false,
    profile_image: false,
  });

  const [isUploaded, setIsUploaded] = useState({
    tenth: false,
    twelth: false,
    ug: false,
    pg: false,
    phd: false,
    other: false,
    profile_image: false,
  });

  const [formDataState, setFormDataState] = useState({
    tenth: "",
    twelth: "",
    ug: "",
    pg: "",
    phd: "",
    other: "",
    profile_image: "",
  });

  const fileInputRef = useRef(null);

  const updatePasswordFields = (key, value) => {
    setUserData((prevData) => ({
      ...prevData,
      [key]: value,
    }));

    if (key === "confirmPassword") {
      if (userData.password !== value) {
        setPasswordError("Passwords do not match");
      } else {
        setPasswordError("");
      }
    }
  };
  function handleFormDetails(e) {
    setUserData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  }

  const handleDocumentName = (event) => {
    const { name, value } = event.target;
    setDocumentName(value);
  };
  //  useEffect(()=>{
  //   if(userData?.country === null || userData?.country === undefined ||
  //     userData?.countrry == "" || typeof(userData?.country) !== Number
  //   ){
  //     setUserData((obj)=>({...obj,country:"101"}))
  //   }
  // },[]);

  //Document Upload
  const handleFileChange = (e) => {
    const formData = new FormData();
    const file = e.target.files[0];

    if (file && file.size > 2 * 1024 * 1024) {
      // 2MB = 2 * 1024 * 1024 bytes
      // Display an error message or handle the error
      Swal.fire(
        "Oops",
        "File size exceeds 2MB. Please upload a smaller file",
        "error"
      );

      setIsDisabledButton({
        ...isDisabledButton,
        cancelled_passbook_cheque_upload: true,
      });
      return;
    }

    formData.append(documentName, file);
    formData.append("id", user?.id);

    setFormDataState({ ...formData, [documentName]: formData });

    if (file) {
      setIsDisabledButton({ ...isDisabledButton, [documentName]: false });
    }
  };
  // console.log(formDataState, "formDatastate")
  console.log(correctionData, "correc==>");

  const handleDocumentUpload = async () => {
    setIsLoadingUpload({ ...isLoadingUpload, [documentName]: true });
    try {
      let response = await UploadDocument(formDataState[documentName]);
      if (response?.data?.status === true) {
        Swal.fire({
          title: "Success!",
          text: `${response?.data?.message}`,
          icon: "success",
        });
        setIsDisabledButton({ ...isDisabledButton, [documentName]: true });
        setIsUploaded({ ...isUploaded, [documentName]: true });

        const docs = response?.data?.data;

        setUserDocuments((prevState) => ({
          ...prevState,
          [documentName]: true,
          [`${documentName}Document`]: docs.doc_file,
        }));
      } else {
        if (response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.message}`,
          });
        } else {
          console.log("error", response.data.message);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.message}`,
          });
        }
      }
    } catch (error) {
      console.log(error, "error");

      // Check if error response and data.message exist
      const message = error?.response?.data?.message;

      // If message is an object and has keys, extract the first key and value
      let firstValue = "An unexpected error occurred.";
      if (typeof message === "object" && Object.keys(message).length > 0) {
        const firstKey = Object.keys(message)[0];
        firstValue = message[firstKey]?.[0] || firstValue;
      } else if (typeof message === "string") {
        // If message is a string, use it directly
        firstValue = message;
      }

      Swal.fire("Oops", `${firstValue}`, "error");
    } finally {
      setIsLoadingUpload({ ...isLoadingUpload, [documentName]: false });
    }
  };

  //profile pic upload

  const handleProfilePicUpload = async (e) => {
    setIsLoadingUpload({ ...isLoadingUpload, other: true });

    try {
      let response = await UploadDocument(formDataState.profile_image);
      if (response?.data?.status === true) {
        Swal.fire({
          title: "Success!",
          text: `${response?.data?.message}`,
          icon: "success",
        });
        setIsDisabledButton({ ...isDisabledButton, profile_image: true });
        setIsUploaded({ ...isUploaded, profile_image: true });

        const docs = response?.data?.data;

        setUserDocuments((prevState) => ({
          ...prevState,
          profile_image: true,
          profile_image_link: docs.doc_file,
        }));
      } else {
        if (response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.message}`,
          });
        } else {
          console.log("error", response.data.message);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.message}`,
          });
        }
      }
    } catch (error) {
      console.log(error, "error");

      // Check if error response and data.message exist
      const message = error?.response?.data?.message;

      // If message is an object and has keys, extract the first key and value
      let firstValue = "An unexpected error occurred.";
      if (typeof message === "object" && Object.keys(message).length > 0) {
        const firstKey = Object.keys(message)[0];
        firstValue = message[firstKey]?.[0] || firstValue;
      } else if (typeof message === "string") {
        // If message is a string, use it directly
        firstValue = message;
      }

      Swal.fire("Oops", `${firstValue}`, "error");
    } finally {
      setIsLoadingUpload({ ...isLoadingUpload, profile_image: false });
    }
  };

  const handleProfileImageChange = async (e) => {
    const file = e.target.files[0];

    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
    if (!allowedTypes.includes(file.type)) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Only JPG, JPEG, and PNG files are allowed.",
      });

      e.target.value = ""; // Clear the input
      return;
    }

    if (file && file.size > 2 * 1024 * 1024) {
      // 2MB = 2 * 1024 * 1024 bytes
      // Display an error message or handle the error
      Swal.fire(
        "Oops",
        "Image size exceeds 2MB. Please upload a smaller file",
        "error"
      );

      setIsDisabledButton({ ...isDisabledButton, profile_image: true });
      return;
    }

    const formData = new FormData();

    formData.append("profile_image", file);
    formData.append("id", user?.id);
    setFormDataState({ ...formDataState, profile_image: formData });
    if (file) {
      setIsDisabledButton({ ...isDisabledButton, profile_image: false });
    }
  };

  const handleReUpload = () => {
    setIsUploaded({ ...isUploaded, profile_image: false });
    setUserDocuments({ ...userDocuments, profile_image: false });
  };

  const handleViewClick = (docUrl) => {
    window.open(docUrl, "_blank");
  };

  const handleDocumentReupload = () => {
    setIsUploaded({ ...isUploaded, [documentName]: false });
    setUserDocuments({ ...userDocuments, [documentName]: false });
  };

  const handlePasswordReset = () => {
    setIsPasswordSet(false);
  };

  const handleClosePassword = () => {
    setIsPasswordSet(true);
  }

  // console.log(userData, "usesssrdata==>");

  return (
    <div className="personal-details-registerpage-main-container">
      <div className="personal-details-container">
        <p className="personal-details-header">Personal Details</p>
      </div>

      <div className="personal-details-email-password">
        <div className="email-password-1">
          <label className="label-pd">First Name</label>
          <CFormInput
            name="first_name"
            value={userData?.first_name}
            onChange={handleFormDetails}
            placeholder="Enter First Name"
            onKeyDown={(e) => {
              // Allow only letters, space, and some common punctuation marks
              if (/[^a-zA-Z\s'.,-]/.test(e.key)) {
                e.preventDefault();
              }
            }}
            onPaste={(event) => {
              event.preventDefault();
            }}
            onDrop={(event) => {
              event.preventDefault();
            }}
            disabled={
              userData.status === "ask_for_resubmit" &&
              !correctionData?.hasOwnProperty("first_name")
            }
            text={
              (userData.status === "ask_for_resubmit" &&
                correctionData?.first_name?.remark && (
                  <>
                    <span style={{ color: "red" }}>
                      {correctionData.first_name.remark}
                    </span>
                  </>
                )) ||
              (userData.status == "ongoing" && !userData.first_name && (
                <>
                  <span style={{ color: "red" }}>
                    Please fill in the first name.
                  </span>
                </>
              ))
            }
          />
        </div>

        <div className="email-password-1">
          {/* <label
            className=""
            style={{
              padding: "1rem 0rem 1rem 0rem",
              maxWidth: "cit-content",
            }}
          >
            Middle Name
          </label> */}
           <label className="contentbox">Middle Name</label>
          <CFormInput
            name="middle_name"
            value={userData?.middle_name}
            onChange={handleFormDetails}
            placeholder="Enter Middle Name "
            onKeyDown={(e) => {
              if (/\d/.test(e.key)) {
                e.preventDefault();
              }
            }}
            onPaste={(event) => {
              event.preventDefault();
            }}
            onDrop={(event) => {
              event.preventDefault();
            }}
            disabled={
              userData.status === "ask_for_resubmit" &&
              !correctionData?.hasOwnProperty("middle_name")
            }
            text={
              userData.status === "ask_for_resubmit" &&
              correctionData?.middle_name?.remark && (
                <>
                  <span style={{ color: "red" }}>
                    {correctionData.middle_name.remark}
                  </span>
                </>
              )
            }
          />
        </div>

        <div className="email-password-1">
          {/* <label
            className=""
            style={{
              padding: "1rem 0rem 1rem 0rem",
              maxWidth: "cit-content",
            }}
          >
            Last Name
          </label> */}
            <label className="contentbox">Last Name</label>
          <CFormInput
            name="last_name"
            value={userData?.last_name}
            onChange={handleFormDetails}
            placeholder="Enter Last Name "
            onKeyDown={(e) => {
              if (/\d/.test(e.key)) {
                e.preventDefault();
              }
            }}
            onPaste={(event) => {
              event.preventDefault();
            }}
            onDrop={(event) => {
              event.preventDefault();
            }}
            disabled={
              userData.status === "ask_for_resubmit" &&
              !correctionData?.hasOwnProperty("last_name")
            }
            text={
              userData.status === "ask_for_resubmit" &&
              correctionData?.last_name?.remark && (
                <>
                  <span style={{ color: "red" }}>
                    {correctionData.last_name.remark}
                  </span>
                </>
              )
            }
          />
        </div>

        <div className="email-password-1">
          <label className="label-pd">Fathers Name</label>
          <CFormInput
            name="father_name"
            value={userData?.father_name}
            onChange={handleFormDetails}
            placeholder="Enter Father's Name"
            onKeyDown={(e) => {
              // Allow only letters, space, and some common punctuation marks
              if (/[^a-zA-Z\s'.,-]/.test(e.key)) {
                e.preventDefault();
              }
            }}
            onPaste={(event) => {
              event.preventDefault();
            }}
            onDrop={(event) => {
              event.preventDefault();
            }}
            disabled={
              userData.status === "ask_for_resubmit" &&
              !correctionData?.hasOwnProperty("father_name")
            }
            text={
              (userData.status === "ask_for_resubmit" &&
                correctionData?.father_name?.remark && (
                  <>
                    <span style={{ color: "red" }}>
                      {correctionData.father_name.remark}
                    </span>
                  </>
                )) ||
              (userData.status == "ongoing" && !userData.father_name && (
                <>
                  <span style={{ color: "red" }}>
                    Please fill in the father's name
                  </span>
                </>
              ))
            }
          />
        </div>

        <div className="email-password-2">
          <label className="label-pd">DoB</label>
          <CFormInput
            type="date"
            name="dob"
            max={today_date}
            value={userData?.dob}
            onChange={handleFormDetails}
            placeholder="Enter DoB"
            disabled={
              userData.status === "ask_for_resubmit" &&
              !correctionData?.hasOwnProperty("dob")
            }
            text={
              (userData.status === "ask_for_resubmit" &&
                correctionData?.dob?.remark && (
                  <>
                    <span style={{ color: "red" }}>
                      {correctionData.dob.remark}
                    </span>
                  </>
                )) ||
              (userData.status == "ongoing" && !userData.dob && (
                <>
                  <span style={{ color: "red" }}>Please fill in you DOB.</span>
                </>
              ))
            }
          />
        </div>
        <div className="personal-details-address-1 astrick-ld">
          <label className="label-pd">Address</label>
          <CFormInput
            placeholder="Enter Address"
            name="address"
            value={userData?.address}
            onChange={handleFormDetails}
            disabled={
              userData.status === "ask_for_resubmit" &&
              !correctionData?.hasOwnProperty("address")
            }
            text={
              (userData.status === "ask_for_resubmit" &&
                correctionData?.address?.remark && (
                  <>
                    <span style={{ color: "red" }}>
                      {correctionData.address.remark}
                    </span>
                  </>
                )) ||
              (userData.status == "ongoing" && !userData.address && (
                <>
                  <span style={{ color: "red" }}>Please fill your address</span>
                </>
              ))
            }
          />
        </div>
        {/* <div className="email-password-3">
          <label className="label-pd">Age</label>
          <input
            name="age"
            value={userData?.age}
            onChange={handleFormDetails}
            placeholder="Enter Age"
          />
        </div> */}
      </div>
      <div className="personal-details-email-password">
        <div className="email-password-1">
          <label className="label-pd">Gender</label>
          <CFormSelect
            aria-label="Select other user Type"
            options={[
              { label: "Select Gender", value: "" },
              { label: "Male", value: "M" },
              { label: "Female", value: "F" },
              { label: "Other", value: "O" },
            ]}
            name="gender"
            value={userData?.gender || "Select Gender"}
            onChange={handleFormDetails}
            disabled={
              userData.status === "ask_for_resubmit" &&
              !correctionData?.hasOwnProperty("gender")
            }
            text={
              (userData.status === "ask_for_resubmit" &&
                correctionData?.gender?.remark && (
                  <>
                    <span style={{ color: "red" }}>
                      {correctionData.gender.remark}
                    </span>
                  </>
                )) ||
              (userData.status == "ongoing" && !userData.gender && (
                <>
                  <span style={{ color: "red" }}>Select Gender</span>
                </>
              ))
            }
          />
        </div>
        <div className="email-password-2">
          <label className="label-pd">Email ID</label>
          <CFormInput
            name="email"
            value={userData?.email}
            onChange={handleFormDetails}
            placeholder="Enter Email ID"
            disabled
            text={
              <>
                <span style={{ color: "blueviolet" }}>Verified</span>
              </>
            }
          />
        </div>
        <div className="email-password-3">
          <label className="label-pd">Marital Status</label>
          <CFormSelect
            aria-label="Select other user Type"
            name="maritial_status"
            value={userData?.maritial_status}
            onChange={handleFormDetails}
            options={[
              { label: "Select Marital Status", value: "" },
              { label: "Married", value: "Married" },
              { label: "UnMarried", value: "UnMarried" },
            ]}
            disabled={
              userData.status === "ask_for_resubmit" &&
              !correctionData?.hasOwnProperty("maritial_status")
            }
            text={
              (userData.status === "ask_for_resubmit" &&
                correctionData?.maritial_status?.remark && (
                  <>
                    <span style={{ color: "red" }}>
                      {correctionData.maritial_status.remark}
                    </span>
                  </>
                )) ||
              (userData.status == "ongoing" && !userData.maritial_status && (
                <>
                  <span style={{ color: "red" }}>Select Marital Status</span>
                </>
              ))
            }
          />
        </div>
      </div>

      <div className="personal-details-register-as">
        <div className="left-side">
          <div className="email-password-1 pd-1">
            <label className="label-pd">Highest Achievement</label>
            <CFormSelect
              aria-label="Select other user Type"
              name="highestAchievement"
              value={documentName}
              onChange={handleDocumentName}
              options={[
                { label: "10th", value: "tenth" },
                { label: "12th", value: "twelth" },
                { label: "Graduation", value: "ug" },
                { label: "Post Graduation", value: "pg" },
                { label: "PHD", value: "phd" },
                { label: "Other", value: "other" },
              ]}
              disabled={
                userData.status === "ask_for_resubmit" &&
                !correctionData?.hasOwnProperty("highestAchievement")
              }
            />
          </div>
          <div className="email-password-1 pd-1" style={{ display: "flex" }}>
            <div>
              <label className="label-pd">Upload Certificate</label>
              <CFormInput
                type="file"
                id="formFile"
                className="input-pd"
                style={{ border: "1px solid grey" }}
                name="highestAchievementCertificate"
                onChange={handleFileChange}
                disabled={
                  userData.status === "ask_for_resubmit" &&
                  !correctionData?.hasOwnProperty("highestAchievement")
                }
              />
                              <p className="upload-warning">* Click on Upload Button after selecting the file.</p>
            </div>
            {/* { console.log( userDocuments[`${documentName}Document`],  "uploaded")} */}
            <div className="d-flex gap-3 mt-1">
              <div>
                <CButton
                  style={{ backgroundColor: "#5d41af" }}
                  onClick={handleDocumentUpload}
                  disabled={isDisabledButton[documentName]}
                >
                  {isUploaded[documentName] || userDocuments[documentName]
                    ? "Uploaded"
                    : isLoadingUpload[documentName]
                    ? "Uploading..."
                    : "Upload"}
                </CButton>
              </div>
              <div>
                {userDocuments[documentName] && (
                  <div>
                    <CButton
                      onClick={() =>
                        handleViewClick(
                          userDocuments[`${documentName}Document`]
                        )
                      }
                      color="success"
                      variant="outline"
                    >
                      View
                    </CButton>
                  </div>
                )}
              </div>
              <div>
                {userDocuments[documentName] && (
                  <div>
                    <CButton
                      onClick={handleDocumentReupload}
                      color="success"
                      variant="outline"
                      disabled={
                        userData.status === "ask_for_resubmit" &&
                        !correctionData?.hasOwnProperty(userDocuments[documentName])
                      }
                    >
                      Reupload
                    </CButton>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* userData?.steps >= 5 &&  */}
          {isPasswordSet && userData?.status == "ongoing" && (
            <CButton
              className="reset-password-btn"
              type="submit"
              color="primary"
              onClick={handlePasswordReset}
            >
              Do you want to Reset your Password ?
            </CButton>
          )}
          {/* userData?.steps < 5 &&  */}
          {userData?.status == "ongoing" && !isPasswordSet && (
            <>
              <div className="email-password-2">
                <label className="label-pd">Create Password</label>
                <div>
                  <CInputGroup>
                    <CFormInput
                      placeholder="Enter Create Password"
                      type={showPassword ? "text" : "password"}
                      value={userData?.password || ""}
                      onChange={(e) =>
                        updatePasswordFields("password", e.target.value)
                      }
                      disabled={
                        userData.status === "ask_for_resubmit" &&
                        !correctionData?.hasOwnProperty("password")
                      }
                    />

                    <CInputGroupText
                      onClick={() => setShowPassword(!showPassword)}
                      style={{ cursor: "pointer" }}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </CInputGroupText>
                  </CInputGroup>
                  <CFormText className="text-danger">
                    {userData.status === "ongoing" && !userData.password && (
                      <span style={{ color: "red" }}>Password is required</span>
                    )}
                  </CFormText>
                </div>
              </div>
              <div className="email-password-3">
                <label className="label-pd">Confirm Password</label>
                <div>
                  <CInputGroup>
                    <CFormInput
                      placeholder="Enter Confirm Password"
                      type={showConfirmPassword ? "text" : "password"}
                      value={userData?.confirmPassword || ""}
                      onChange={(e) =>
                        updatePasswordFields("confirmPassword", e.target.value)
                      }
                      disabled={
                        userData.status === "ask_for_resubmit" &&
                        !correctionData?.hasOwnProperty("password")
                      }
                    />

                    <CInputGroupText
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                    </CInputGroupText>
                  </CInputGroup>
                </div>

                <CFormText className="text-danger">
                  {passwordError && (
                    <span style={{ color: "red" }}>{passwordError}</span>
                  )}
                </CFormText>
              </div>
              {isPasswordClose && (
                <div>
                  <CButton
                    color="danger"
                    className="mt-1"
                    onClick={handleClosePassword}
                  >
                    Click to Skip Password Change
                  </CButton>
                </div>
              )}
              {/* <div>
              <CButton color="danger"  className="mt-5" onClick={handleClosePassword}>Close</CButton>
              </div> */}
              
            </>
          )}
        </div>
        <div className="image-container">
          <div className="email-password-1">
            <label className="label-pd">Upload Profile Photo</label>
          </div>

          <div>
            {userDocuments?.profile_image ? (
              <div>
                <img
                  width="120"
                  height="100"
                  src={userDocuments.profile_image_link}
                  style={{ border: "1px solid grey" }}
                  alt="Profile"
                />
              </div>
            ) : (
              <div>
                <img
                  width="120"
                  height="100"
                  src={imageSrc}
                  style={{ border: "1px solid grey" }}
                  alt="Profile"
                />
              </div>
            )}
          </div>
          <div>
            <div>
              <div>
                <CFormInput
                  name="profile_image"
                  onChange={handleProfileImageChange}
                  type="file"
                  id="formFile"
                  accept=".jpg,.jpeg,.png"
                  disabled={
                    userDocuments?.profile_image || isUploaded?.profile_image
                  }
                  text={
                    <>
                      Only PNG, JPG, JPEG files are allowed.
                      <br />
                      {userData.status === "ask_for_resubmit" &&
                      !correctionData?.hasOwnProperty("profile_image") ? (
                        <span style={{ color: "red" }}>
                          {correctionData?.profile_image?.remark}
                        </span>
                      ) : userData.status === "ongoing" &&
                        !userDocuments?.profile_image ? (
                        <>
                          <br />
                          <span style={{ color: "red" }}>
                            Please upload a profile image.
                          </span>
                        </>
                      ) : null}
                    </>
                  }
                />
              </div>
              <p className="upload-warning">* Click on Upload Button after selecting the file.</p>

              <div className="upload-field d-flex gap-3">
                <button
                  className="upload-btn-pd"
                  onClick={handleProfilePicUpload}
                  disabled={isDisabledButton?.profile_image}
                  style={{
                    backgroundColor: "#726fb7",
                    color: "#fff",
                  }}
                >
                  <span>
                    {isUploaded?.profile_image || userDocuments?.profile_image
                      ? "Uploaded"
                      : isLoadingUpload?.profile_image
                      ? "Uploading..."
                      : "Upload"}
                  </span>
                </button>
                {userDocuments?.profile_image && (
                  <div>
                    <CButton
                      onClick={() =>
                        handleViewClick(`${userDocuments.profile_image_link}`)
                      }
                      color="success"
                      variant="outline"
                    >
                      View
                    </CButton>
                  </div>
                )}
                {/* {console.log(correctionData, "00000==>")} */}
                {userDocuments?.profile_image && (
                  <div>
                    <CButton
                      color="success"
                      variant="outline"
                      onClick={handleReUpload}
                      disabled={
                        userData?.status == "ask_for_resubmit" &&
                        !correctionData?.hasOwnProperty("profile_image")
                      }
                    >
                      Reupload
                    </CButton>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalRegisterDetails;
