import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import "./styles.scss";
import Header from "../../components/Header";
import img1 from "../../asserts/LatestNews/img1.png";
import leftBG from "../../asserts/LatestNews/left-news.png";
import readNow from "../../asserts/LatestNews/readNow.png";
import mapReadNow from "../../asserts/LatestNews/mapReadNow.png";
import news from "../../asserts/LatestNews/news.png";
import topNewsImg1 from "../../asserts/LatestNews/topnewsImg1.png";
import topNewsImg2 from "../../asserts/LatestNews/topnewsImg2.png";
import topNewsImg3 from "../../asserts/LatestNews/topnewsImg3.png";
import Slider from "../../components/LatestSlider/Index";
import SliderCardLatestNews from "./SliderCardLatestNews";
import newsImg from "../../asserts/nodata.png"
import axios from "axios";


function LatestNews() {
  const { hash } = useLocation();
  const navigate = useNavigate();


  const [news, setNews] = useState([]);
  console.log("news===>", news)

  const newsdetailsHandler = () => {
    navigate("/latest-new-detilas");
  };

  useEffect(() => {
    // Fetch news data from the API
    const fetchNews = async () => {
      try {
        const response = await axios.get("https://media-api.jharkhandsports.in/api/news/all");
        setNews(response.data.records); // Assuming the response data structure is an array
      } catch (error) {
        console.error("Error fetching news data:", error);
      }
    };

    fetchNews();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.replace("#", ""));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);
  return (
    <div>
      <div className="latest-news-container">
        <div className="container latest_news_container">
          <div className="heading-text text-center">
            <h3 className="lates_news_heading mt-5">LATEST NEWS</h3>
          </div>
        </div>
        {/* <div className="slider-cont">
          <Slider />
        </div> */}
      </div>

      {/* <Container className="">
        <Row>
          <Col lg={12}>
            <div className="latest-news-contents">
              <p className="trending-now-heading" id="jharkhand-latest-news">
                Trending Now
              </p>

              {trendingNews.map((news, index) => (
                <div className="trending-now-container">
                  <div className="left-img-container" key={index}>
                    <div className="left-img-latest-news">
                      <img src={news.img} width="600" className="newsImg" />
                      <div className="left-news">
                        <img src={news.leftlBG} width="230" />
                      </div>
                    </div>
                  </div>
                  <div className="right-content-container">
                    <div>
                      <p>{news.date}</p>
                    </div>
                    <div>
                      <h4>
                        <b>
                          22-member Indian women's hockey team announced for
                          5-nation tournament Valencia 2023, 4 players from
                          Jharkhand in the team
                        </b>
                      </h4>
                    </div>
                    <div>
                      <p>{news.desc}</p>
                    </div>
                    <div className="mt-3">
                      <button className="readNow-btn">Read Now</button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="mb-5 " id="new_sub">
        <p className="top-news-heading">Top News99</p>
        <Row>
          {topNews.map((data) => {
            return (
              <>
                <Col lg={4}>
                  <Card className="p-0 m-0 h-100 top_news_container">
                    <Card.Img variant="top" src={data?.img} />
                    <Card.Body>
                      <div className="map-details">
                        <div>
                          <img src={data.news} width="120" />
                        </div>
                        <div>
                          <p>{data.date}</p>
                        </div>
                      </div>
                      <Card.Text className="">
                        <div className="mapd-heading">
                          <p>{data.heading}</p>
                        </div>
                      </Card.Text>
                      <button className="readNow-btn-maps ">Read Now</button>
                    </Card.Body>
                  </Card>
                </Col>
              </>
            );
          })}
        </Row>
      </Container> */}

      <div className="">

        <div>
          {news && news.length > 0 ? (
            <>
              {/* Main News Section */}
              <div className="px-5">
                <h2 className="bold mb-4 top-news-heading">Top News</h2>
                <div className="newsMainInnerSection">

                  <div className="postion_rel">
                    <img
                      loading="lazy"
                      alt="news"
                      src={`https://media-api.jharkhandsports.in/news/${news?.[0]?.newsImage}`}
                      style={{
                        width: "100%",
                        maxWidth: "880px",
                        height: "100%",
                        objectFit: "fill",
                        borderRadius: "32px",
                      }}
                    />
                    <div className="newsTag">
                      News
                      <div className="newsImageExtraTag" />
                    </div>
                  </div>
                  <div className="second-news-main mt-5">
                    <p className={`multipleNewsInsideSectionTime bold`}>
                      {dayjs(news?.[0]?.createdAt).format("MMM DD, YYYY")}
                    </p>
                    <h1 className="bold text-3xl heading-mainNews">{news?.[0]?.title}</h1>
                    <p className="color-light-gray line-clamp-11">
                      <MarkupComponent htmlContent={news?.[0]?.description} />
                    </p>
                    <button
                      className="commonBtnPinkGradientBig"
                      onClick={() => navigate(`/latest-news/${news[0].id}`)}
                    >
                      Read Now
                    </button>
                  </div>
                </div>
              </div>


              {/* Top News Section */}
              <div className="my-5">
                <div className="multipleNewsSection" style={{ gap: "4rem" }}>
                  <h2 className="bold news-section-heading">Trending Now</h2>
                  {news.map((item, id) => {
                    return (
                      <>
                        <div key={item?.mlsnumber || id} className="slider-main">
                          <SliderCardLatestNews tileData={item} id={id} />
                        </div>
                      </>
                    )

                  })}
                </div>
              </div>
            </>
          ) : (
            <div className="no-data-container text-center py-4">
              <h3 className="data_not_fount_title">News not available at the moment...</h3>
            </div>
          )}
        </div>

      </div>
    </div>
  );
}

export default LatestNews;

const MarkupComponent = ({ htmlContent }) => {
  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};
