import { useState } from "react";

import "./styles.scss";
import PushkarSinghBanner from "../../asserts/OrganisationalStructure/Shri Pushkar Singh Dhami bannner.webp";
import RekhaAryaBanner from "../../asserts/OrganisationalStructure/Mrs. Rekha Arya banner.webp";
import AmitSinhaBanner from "../../asserts/OrganisationalStructure/Mr. Amit Sinha I.P.S banner.webp";
import sushant from "../../asserts/OrganisationalStructure/Sandip Kumar, JAS.png";
import OrganisationalDetails from "../../components/OrganisationalDetails";
import PushkarSingh from "../../asserts/OrganisationalStructure/Henamt Soren.png";
import RekhaArya from "../../asserts/OrganisationalStructure/Mithilesh Kumar Thakur.png";
import sushantBanner from "../../asserts/OrganisationalStructure/sushantBanner.svg";
import JitendraKumar from "../../asserts/OrganisationalStructure/Mr. Jitendra Kumar Sonakar.webp";
import Mask from "../../asserts/OrganisationalStructure/Mask group.webp";
import Mask_Rekha from "../../asserts/OrganisationalStructure/Mask_Rekha.webp";
import Mask_Amith from "../../asserts/OrganisationalStructure/Mask_Amith.webp";
import Mask_Jitendra from "../../asserts/OrganisationalStructure/Mask_Jitendra.webp";
import ManojKumar from "../../asserts/OrganisationalStructure/Shri Manoj Kumar.png";
import ManojKumarBanner from "../../asserts/OrganisationalStructure/manojkumarbanner.svg";
import sudiyakumar from "../../asserts/OrganisationalStructure/sudivyakumar.jpg"
import Slider from "../../components/SwipperSlider/index";

import Marque from "../../components/Marque/Index"
import { Col, Container, Row } from "react-bootstrap";

const OrganisationalStructure = () => {
  const organisationalProfile = [
    {
      name: "Shri Sudivya Kumar",
      position: `<b>Hon'ble Minister-Cum-President</b>`,
      state: "",
      address:
        "Sports Authority of Jharkhand & Department of Tourism, Art-Culture, Sports and Youth Affairs, Government of Jharkhand",
      contact:
        "",
      backgroundImage: PushkarSinghBanner,
      backgroundImage1: Mask,
      image: sudiyakumar,
      value: "Shri Sudivya Kumar",
    },

    // {
    //   name: "Shri Mithilesh Kumar Thakur",
    //   position:
    //     "<b>Hon'ble Minister-Cum-President Sports Authority of Jharkhand </b>",
    //   state:
    //     "Department of Tourism, Art-Culture, Sports and Youth Affairs, Government of Jharkhand.",
    //   address: "",
    //   contact: "",
    //   backgroundImage: RekhaAryaBanner,
    //   backgroundImage1: Mask_Rekha,
    //   image: RekhaArya,
    //   value: "Mrs. Rekha Arya",
    // },
    {
      name: "Shri Manoj Kumar, IAS",
      position: "<b>Secretary</b>",
      state: "",
      address:
        "Department of Tourism, Art-Culture, Sports and Youth Affairs, Government of Jharkhand.",
      contact: "",
      backgroundImage: ManojKumarBanner,
      backgroundImage1: Mask,
      image: ManojKumar,
      value: "Shri Pushkar Singh Dhami",
    },
    {
      name: "Shri Sandip Kumar, IAS",
      position:
        "<b>Director Sports and Executive Director, Sports Authority of Jharkhand",
      state: "",
      cum: "",
      address: "",
      contact: "",
      image: sushant,
      backgroundImage: sushantBanner,
      backgroundImage1: Mask_Amith,
      value: "Mr. Amit Sinha, I.P.S",
    },
  ];

  const [selectedCard, setSelectedCard] = useState(
    organisationalProfile.length > 0 ? organisationalProfile[0].name : null
  );

  // const handleNavClick = (name: any) => {
  //   setSelectedCard(name);
  //   const element = document.getElementById(name);
  //   if (element) {
  //     element.scrollIntoView({ behavior: "smooth" });
  //   }
  // };
  const handleNavClick = (name: any) => {
    setSelectedCard(name);
  };

  return (
    <div className="fullback-bg">


      <div className="banner">
        <div className="container center_title organigation_banner_height">
          <div className="banner1-text">
            <span className="organizational">ORGANISATIONAL </span>
            <span className="structure">STRUCTURE</span>
          </div>
          {/* <div className="slider-cont">
            <Slider/>
          </div> */}

        </div>
      </div>

      {/* <Container fluid className=" py-5 ">
        <Row className="py-5">
          <Col lg={12} className="py-5">
         

            <div className="slider-cont">
              <Slider />
            </div>


          </Col>
        </Row>
        <Row>
          <Col lg={12}>

          </Col>
        </Row>

      </Container> */}



      <div className="container">
        <div className="row">
          <div className="d-flex client-name justify-content-lg-around py-5 flex-column flex-lg-row">
            {organisationalProfile.map((item: any) => (
              <a
                key={item.name}
                href={`#${item.name}`}
                onClick={() => handleNavClick(item.name)}
                className={`nav-button ${selectedCard === item.name ? 'active' : 'not-active'}`}
              >
                {item.name}
              </a>
            ))}
          </div>
        </div>
        {organisationalProfile?.map((item: any) => {
          return (
            <div
              key={item.name}
              id={item.name}
              style={{ marginBottom: "50px" }}
            >
              <OrganisationalDetails profile={item} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OrganisationalStructure;