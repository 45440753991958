import React from "react";
import "./SliderCard.scss";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import MarkupComponent from "components/common/markup";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const SliderCardLatestNews = (props ,tileData) => {
  const navigate = useNavigate();
  console.log("porps===>",props?.tileData?.id)
  return (
    <div
      className="latest-news-content-main"
      // onClick={() => navigate(`${props?.id}`)}
    >
      <div
        className="latest-news-card-image"
        style={{
          backgroundImage: `url(${
            props.tileData.newsImage
              ? `https://media-api.jharkhandsports.in/news/${props.tileData.newsImage}`
              : ""
          })`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="latest-news-badge"> NEWS</div>
      </div>
      <div className="latest-news-content-body">
        <div className="latest-news-date-news">
          <div className="latest-news-date-text">
            {dayjs(props?.tileData?.createdAt).format("MMM DD, YYYY")}
          </div>
        </div>
        <h5 className="latest-news-card-title fw-bold">
          {props?.tileData?.title}
        </h5>
        <p className="latest-news-card-text">
          <MarkupComponent htmlContent={props?.tileData?.description} />
        </p>
        <button
  className="latest-news-readnow-text"
  onClick={(e) => {
    e.stopPropagation();
    navigate(`/latest-news/${props?.tileData?.id}`);
  }}
>
  Read Now
</button>
      </div>
    </div>
  );
};

export default SliderCardLatestNews;

const MarkupComponent = ({ htmlContent }) => {
  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};
