import { UnAuthApiService } from "../../config/apiServices";
import { GET_ALL_SEARCH_WEBSITE_NOTIFICATION, GET_ALL_WEBSITE_NOTIFICATION } from "../../config/commonRegistartionApi/commonRegistrationApi";

export const getAllWebsiteNotification = (payload,currentPage) => {
  return UnAuthApiService.post(`${GET_ALL_WEBSITE_NOTIFICATION}?page=${currentPage}`);
};

export const getAllWebsiteNotificationMaquee = () => {
  return UnAuthApiService.post(`${GET_ALL_WEBSITE_NOTIFICATION}`);
};

export const getAllWebsiteNotificationBySearch = (payload = {}) => {
  let url = `${GET_ALL_SEARCH_WEBSITE_NOTIFICATION}?`;

  for (let i in payload) {
    url += `${i}=${payload[i]}&`;
  }

  // Remove the trailing '&' and decode the URL
  url = decodeURIComponent(url.slice(0, -1));

  return UnAuthApiService.get(url);
};
