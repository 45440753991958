import React, { useEffect, useState } from "react";

import "./styles.scss";
import sportFedration from "../../asserts/photoGallery/new1.jpeg";
import sportAcademy from "../../asserts/photoGallery/grandSchmes.png";
import hotelInfra from "../../asserts/photoGallery/hostelandInfra.png";
import grantsSch from "../../asserts/photoGallery/grandSchmes.png";
import bookingManagement from "../../asserts/photoGallery/bookingManagement.jpeg";
import search from "../../asserts/photoGallery/search.png";
import LatestSlider from "../../components/LatestSlider/Index";
import Header from "../../components/Header";
import PhotoSlider from "../../components/PhotoSlider";
import s2 from "../../asserts/photoGallery/new2.jpeg";
import s3 from "../../asserts/photoGallery/new3.jpeg";
import s4 from "../../asserts/photoGallery/new4.jpeg";
import s5 from "../../asserts/photoGallery/new5.jpeg";
import s6 from "../../asserts/photoGallery/new6.jpeg";
import s7 from "../../asserts/photoGallery/new7.jpeg";
import s8 from "../../asserts/photoGallery/new8.jpeg";
import s9 from "../../asserts/photoGallery/new9.jpeg";
import s10 from "../../asserts/photoGallery/new10.jpeg";
import s11 from "../../asserts/photoGallery/new11.jpeg";
import s12 from "../../asserts/photoGallery/new12.jpeg";
import s13 from "../../asserts/photoGallery/new13.jpeg";

import s14 from "../../asserts/photoGallery/new14.jpeg";
import s15 from "../../asserts/photoGallery/new15.jpeg";
import s16 from "../../asserts/photoGallery/new16.jpeg";
import s17 from "../../asserts/photoGallery/new17.jpeg";
import s18 from "../../asserts/photoGallery/new18.jpeg";
import s19 from "../../asserts/photoGallery/new19.jpeg";
import s20 from "../../asserts/photoGallery/new20.jpeg";
import s21 from "../../asserts/photoGallery/new21.jpeg";
import s22 from "../../asserts/photoGallery/new22.jpeg";
import s23 from "../../asserts/photoGallery/new23.jpeg";
import Slider from "react-slick";
import { Col, Container, Row } from "react-bootstrap";
import img1 from "../../asserts/photoGallery/img1.png";
import img2 from "../../asserts/photoGallery/img2.png";
import img3 from "../../asserts/photoGallery/img3.png";
import ComingSoon from "../../components/ComingSoonPage/ComingSoon";
import { TbFilesOff } from "react-icons/tb";
import axios from "axios";
import { getMedia } from "../../services/commonApiService/CommonApiService";
import { getAllWebsiteNotificationBySearch } from "../../services/notificationForWebsite/notificationForWebsite";
import { getAllGalleryBySearch } from "../../services/galleryApiService/GalleryApiService";


const PhotoGallery = () => {
  const [gallerydata, setGallerydata] = useState([]);
  const [visibleItems, setVisibleItem] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [searchInputValue, setsearchInputValue] = React.useState("");





  const fetchGallery = async (currentPage) => {
    try {
      const response = await axios.get(`https://media-api.jharkhandsports.in/api/media/all?page=${currentPage}`);
      const newRecords = response?.data?.records || [];
      const totalRecords = response?.data?.totalRecord || 0;
      const updatedGalleryData = [...gallerydata, ...newRecords];
      console.log("updatedGalleryData",updatedGalleryData.length,totalRecords)
      setGallerydata(response?.data?.records);
      if (updatedGalleryData.length >= totalRecords) {
        setHasMoreData(false);
      }

    } catch (error) {
      console.error("Error fetching news data:", error);
    }
  };
  useEffect(() => {
    fetchGallery(currentPage);
  }, [currentPage]);

  const handleLoadMore = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    fetchGallery(currentPage);
    setVisibleItem((prevVisibleItems) => prevVisibleItems + 10);
  }

  const handleFetchSearchData = async () => {
   
    try {
      if (searchInputValue) {
        const sanitizedSearchValue = searchInputValue.replace(/\s+/g, "%20"); 
        let res = await axios.get(`https://media-api.jharkhandsports.in/api/media/all?search=${sanitizedSearchValue}`);
        setGallerydata(res?.data?.records);
      } else {
        fetchGallery(currentPage);
      }
    } catch (error) {
      console.log(error);
    }
  };



  return (
    <div className="Photo-gallery-container ">
      <div className="bgcontainer center_title py-3 ">
        <div className="photo_gallery_container py-3 ">
          <div className="mt-3">
            <h3 className="photoGallery-heading mt-3">PHOTO GALLERY</h3>
          </div>
          <div className="photoGallery-search-field">
            <div>
              <input placeholder="Search" className="input"
              onChange={(e) => setsearchInputValue(e.target.value)} 
              />
            </div>
            <div className="cursur_title">
              <img width="100" src={search}  onClick={handleFetchSearchData} />
            </div>
          </div>

        </div>


      </div>
      <div className="photoGallery-gridPage mt-5">
        {gallerydata && gallerydata.length > 0 ? (
          <div className="">
            <div className="container-element container-element-1 mb-5">
              {gallerydata.slice(0, visibleItems).map((item) => (
                <div
                  key={item.id} 
                  className="grid-item"
                  style={{
                    backgroundImage: `url(https://media-api.jharkhandsports.in/media/${item?.image})`,
                  }}
                >
                  <div className="overlay">
                    {item?.title ? item.title.substring(0, 20) + "..." : "No Title"}
                  </div>
                </div>
              ))}
            </div>
            {hasMoreData && (
              <div className="text-center mb-4">
                <button className="load-more-btn" onClick={handleLoadMore}>
                  Load More
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="no-data-container text-center py-4">
            <h3 className="data_not_fount_title">Gallery not available at the moment...</h3>
          </div>
        )}


      </div>
    </div>
  );
};

export default PhotoGallery;


