import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import Swal from "sweetalert2";
import { FaEye, FaEyeSlash } from "react-icons/fa";
// import DataContext from "../../../../helpers/UserDataContext";
import { UploadDocument } from "../../../apiServices/educationApi";
import {
  CFormSelect,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CInputGroupText,
  CFormText,
} from "@coreui/react";
import { CButton } from "@coreui/react";
import { get_Registration_Listing } from "../../../../config/registrationConfigUrl";
import { VerticalStepperContext } from "../../../../Context/VerticalStepperCount/VerticalStepper";

function BankDetails() {
  const [showAccountnumber, setShowAccountnumber] = useState(false);
  // const { userData, setUserData } = useContext(DataContext);
  const navigate = useNavigate();
  const {
    userDocuments,
    setUserDocuments,
    userData,
    setUserData,
    correctionData,
    currentStep,
  } = useContext(VerticalStepperContext);
  const user = JSON.parse(localStorage.getItem("user"));
  const [isDisabledButton, setIsDisabledButton] = useState({
    cancelled_passbook_cheque_upload: true,
  });

  const [isLoadingUpload, setIsLoadingUpload] = useState({
    cancelled_passbook_cheque_upload: false,
  });

  const [isUploaded, setIsUploaded] = useState({
    cancelled_passbook_cheque_upload: false,
  });

  const [formData, setFormData] = useState({
    cancelled_passbook_cheque_upload: "",
  });

  // const getRegistrationList = async () => {
  //   try {
  //     let response = await get_Registration_Listing(user?.id);
  //     if (response?.data?.status === true) {
  //       let country = {};
  //       if (response?.data?.data?.country === null) {
  //         country.country = "101";
  //       }
  //       setUserData({ ...userData, ...response?.data?.data, ...country });
  //     } else {
  //       if (response?.data?.message) {
  //         console.log("error", response.data.message);
  //       } else {
  //         console.log("error", response.data.message);
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error, "error");
  //     //   Swal.fire("Oops", `${error?.response?.data?.message}`, "error");
  //   }
  // };

  // useEffect(() => {
  //   //info
  //   getRegistrationList();
  // }, []);

  useEffect(() => {
    // console.log(userData, "====>");
    if (userData.status === "final_submit") {
      localStorage.removeItem("currentStep");
      localStorage.removeItem("access_token");
      localStorage.removeItem("user");
      localStorage.removeItem("login_Details");
      localStorage.removeItem("bankDetails");
      navigate("/", { replace: true });
    }
  }, [userData.status, currentStep]);

  function handleFormDetails(e) {
    const { name, value } = e.target;

    // Update the userData state
    setUserData((prev) => {
      return { ...prev, [name]: value };
    });
  }

  useEffect(() => {}, [userData]);

  const handleCancelledCheckUpload = async () => {
    try {
      let response = await UploadDocument(
        formData.cancelled_passbook_cheque_upload
      );
      if (response?.data?.status === true) {
        Swal.fire({
          title: "Success!",
          text: `${response?.data?.message}`,
          icon: "success",
        });
        setIsDisabledButton({
          ...isDisabledButton,
          cancelled_passbook_cheque_upload: true,
        });
        const docs = response?.data?.data;

        setUserDocuments((prevState) => ({
          ...prevState,
          cancelled_passbook_cheque_upload: true,
          cancelled_passbook_cheque_upload_link: docs.doc_file,
        }));
      } else {
        if (response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.message}`,
          });
        } else {
          console.log("error", response.data.message);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.message}`,
          });
        }
      }
    } catch (error) {
      console.log(error, "error");

      // Check if error response and data.message exist
      const message = error?.response?.data?.message;

      // If message is an object and has keys, extract the first key and value
      let firstValue = "An unexpected error occurred.";
      if (typeof message === "object" && Object.keys(message).length > 0) {
        const firstKey = Object.keys(message)[0];
        firstValue = message[firstKey]?.[0] || firstValue;
      } else if (typeof message === "string") {
        // If message is a string, use it directly
        firstValue = message;
      }

      Swal.fire("Oops", `${firstValue}`, "error");
    } finally {
      setIsLoadingUpload({
        ...isLoadingUpload,
        cancelled_passbook_cheque_upload: false,
      });
    }
  };

  const handleCancelledChequeChange = async (e) => {
    const formData = new FormData();
    const file = e.target.files[0];

    if (file && file.size > 2 * 1024 * 1024) {
      // 2MB = 2 * 1024 * 1024 bytes
      // Display an error message or handle the error
      Swal.fire(
        "Oops",
        "File size exceeds 2MB. Please upload a smaller file",
        "error"
      );

      setIsDisabledButton({
        ...isDisabledButton,
        cancelled_passbook_cheque_upload: true,
      });
      return;
    }

    formData.append("cancelled_passbook_cheque_upload", file);
    formData.append("id", user?.id);

    setFormData({ ...formData, cancelled_passbook_cheque_upload: formData });

    if (file) {
      setIsDisabledButton({
        ...isDisabledButton,
        cancelled_passbook_cheque_upload: false,
      });
    }
  };

  const handleViewClick = (docUrl) => {
    window.open(docUrl, "_blank");
  };
  const handleCheckReupload = async () => {
    setIsUploaded({ ...isUploaded, cancelled_passbook_cheque_upload: false });
    setUserDocuments({
      ...userDocuments,
      cancelled_passbook_cheque_upload: false,
    });
  };
  console.log(
    correctionData,
    "correctiondata==>",
    !correctionData?.hasOwnProperty("cancelled_passbook_cheque_upload")
  );

  // useEffect(() => {
  //   const storedBankDetails = JSON.parse(localStorage.getItem('bankDetails'));
  //   if (storedBankDetails && storedBankDetails.bank_name) {
  //     setUserData((prev) => ({
  //       ...prev,
  //       bank_name: storedBankDetails.bank_name,
  //       account_holder_name: storedBankDetails.account_holder_name,
  //       bank_account_number:storedBankDetails.bank_account_number,
  //       ifsc_code: storedBankDetails.ifsc_code,
  //       branch_name: storedBankDetails.branch_name
  //     }));
  //   }
  // }, []);

  // console.log("bank_name:" ,userData?.status == "ask_for_resubmit" , userData);// !correctionData?.hasOwnProperty("bank_name")

  return (
    <div className="bank-details-container">
      <div className="atom-header-container">
        <p className="atom-header"> Bank Details  </p>
      </div>
      <div className="bank-details-fields">
        <div className="bd-1">
          <div>
            <label className="bank-label">Bank Name</label>
          </div>
          <div className="bd-1-input">
            <CFormInput
              type="text"
              name="bank_name"
              value={userData?.bank_name}
              onChange={handleFormDetails}
              placeholder="Enter Bank Name"
              aria-label="default input example"
              onKeyDown={(e) => {
                // Allow only letters, numbers, space, and common navigation keys
                const regex = /^[a-zA-Z\s]$/;

                // Allow backspace, delete, arrow keys, and other common navigation keys
                const allowedKeys = [
                  "Backspace",
                  "Delete",
                  "ArrowLeft",
                  "ArrowRight",
                  "ArrowUp",
                  "ArrowDown",
                  "Tab",
                ];

                // Prevent default action if the key is not allowed
                if (!regex.test(e.key) && !allowedKeys.includes(e.key)) {
                  e.preventDefault();
                }
              }}
              onPaste={(event) => {
                event.preventDefault();
              }}
              onDrop={(event) => {
                event.preventDefault();
              }}
              disabled={
                userData.status === "ask_for_resubmit" &&
                !correctionData?.hasOwnProperty("bank_name")
              }
              text={
                (userData.status === "ask_for_resubmit" &&
                  correctionData?.bank_name?.remark && (
                    <>
                      <span style={{ color: "red" }}>
                        {correctionData.bank_name.remark}
                      </span>
                    </>
                  )) ||
                (userData.status == "ongoing" && !userData.bank_name && (
                  <>
                    <span style={{ color: "red" }}>Enter Bank Name</span>
                  </>
                ))
              }
            />
          </div>
        </div>
        <div className="bd-2">
          <div>
            <label className="bank-label">Account Holder Name</label>
          </div>
          <div className="bd-1-input">
            <CFormInput
              type="text"
              name="account_holder_name"
              value={userData?.account_holder_name}
              onChange={handleFormDetails}
              placeholder="Enter Account Holder Name"
              aria-label="default input example"
              onDrop={(event) => {
                event.preventDefault();
              }}
              onKeyDown={(e) => {
                // Allow only letters, numbers, space, and common navigation keys
                const regex = /^[a-zA-Z\s]$/;

                // Allow backspace, delete, arrow keys, and other common navigation keys
                const allowedKeys = [
                  "Backspace",
                  "Delete",
                  "ArrowLeft",
                  "ArrowRight",
                  "ArrowUp",
                  "ArrowDown",
                  "Tab",
                ];

                // Prevent default action if the key is not allowed
                if (!regex.test(e.key) && !allowedKeys.includes(e.key)) {
                  e.preventDefault();
                }
              }}
              onPaste={(event) => {
                event.preventDefault();
              }}
              disabled={
                userData.status === "ask_for_resubmit" &&
                !correctionData?.hasOwnProperty("account_holder_name")
              }
              text={
                (userData.status === "ask_for_resubmit" &&
                  correctionData?.account_holder_name?.remark && (
                    <>
                      <span style={{ color: "red" }}>
                        {correctionData.account_holder_name.remark}
                      </span>
                    </>
                  )) ||
                (userData.status == "ongoing" &&
                  (!userData.account_holder_name ||
                    userData.account_holder_name.trim() === "") && (
                    <>
                      <span style={{ color: "red" }}>
                        Enter Account Holder Name
                      </span>
                    </>
                  ))
              }
            />
          </div>
        </div>
        <div className="bd-3">
          <div>
            <label className="bank-label">Account Number</label>
          </div>
          <div className="bd-1-input">
            <CInputGroup>
              <CFormInput
                // type={"number"}
                type={!showAccountnumber ? "password" : "number"}
                name="bank_account_number"
                value={userData?.bank_account_number}
                onChange={handleFormDetails}
                placeholder="Enter Account Number"
                aria-label="default input example"
                maxLength={14}
                onKeyDown={(e) => {
                  // Allow only letters, numbers, space, apostrophe, period, comma, and hyphen
                  const regex = /^[a-zA-Z0-9\s]$/;

                  // Allow backspace, delete, arrow keys, and other common navigation keys
                  const allowedKeys = [
                    "Backspace",
                    "Delete",
                    "ArrowLeft",
                    "ArrowRight",
                    "ArrowUp",
                    "ArrowDown",
                    "Tab",
                  ];

                  if (!regex.test(e.key) && !allowedKeys.includes(e.key)) {
                    e.preventDefault();
                  }

                  // Check if the input exceeds 14 characters
                  if (
                    e.target.value.length >= 14 &&
                    !allowedKeys.includes(e.key)
                  ) {
                    e.preventDefault();
                  }
                }}
                disabled={
                  userData.status === "ask_for_resubmit" &&
                  !correctionData?.hasOwnProperty("bank_account_number")
                }
              />
              <CInputGroupText
                onClick={() => setShowAccountnumber(!showAccountnumber)}
                style={{ cursor: "pointer" }}
              >
                {!showAccountnumber ? <FaEyeSlash /> : <FaEye />}
              </CInputGroupText>
            </CInputGroup>
            <CFormText className="text-danger">
              {(userData.status === "ask_for_resubmit" &&
                correctionData?.bank_account_number?.remark && (
                  <>
                    <span style={{ color: "red" }}>
                      {correctionData.bank_account_number.remark}
                    </span>
                  </>
                )) ||
                (userData.status == "ongoing" &&
                  !userData.bank_account_number && (
                    <>
                      <span style={{ color: "red" }}>
                        Enter Bank Account Number
                      </span>
                    </>
                  ))}
            </CFormText>
          </div>
        </div>
        <div className="bd-4">
          <div>
            <label className="bank-label">IFSC Code</label>
          </div>
          <div className="bd-1-input">
            <CFormInput
              type="text"
              name="ifsc_code"
              value={userData?.ifsc_code}
              onChange={handleFormDetails}
              placeholder="Enter IFSC Code"
              aria-label="default input example"
              onPaste={(event) => {
                event.preventDefault();
              }}
              onDrop={(event) => {
                event.preventDefault();
              }}
              onKeyDown={(e) => {
                // Allow only letters, numbers, and spaces
                const regex = /^[a-zA-Z0-9\s]$/;

                // Allow backspace, delete, arrow keys, and other common navigation keys
                const allowedKeys = [
                  "Backspace",
                  "Delete",
                  "ArrowLeft",
                  "ArrowRight",
                  "ArrowUp",
                  "ArrowDown",
                  "Tab",
                ];

                // Prevent default action if the key is not allowed
                if (!regex.test(e.key) && !allowedKeys.includes(e.key)) {
                  e.preventDefault();
                }
              }}
              disabled={
                userData.status === "ask_for_resubmit" &&
                !correctionData?.hasOwnProperty("ifsc_code")
              }
              text={
                (userData.status === "ask_for_resubmit" &&
                  correctionData?.ifsc_code?.remark && (
                    <>
                      <span style={{ color: "red" }}>
                        {correctionData.ifsc_code.remark}
                      </span>
                    </>
                  )) ||
                (userData.status == "ongoing" && !userData.ifsc_code && (
                  <>
                    <span style={{ color: "red" }}>Enter IFSC Code</span>
                  </>
                ))
              }
            />
          </div>
        </div>
        <div className="bd-5">
          <div>
            <label className="bank-label">Branch Name</label>
          </div>
          <div className="bd-1-input">
            <CFormInput
              name="branch_name"
              value={userData?.branch_name}
              onChange={handleFormDetails}
              type="text"
              placeholder="Enter Branch Name"
              aria-label="default input example"
              onPaste={(event) => {
                event.preventDefault();
              }}
              onDrop={(event) => {
                event.preventDefault();
              }}
              onKeyDown={(e) => {
                // Allow only letters, numbers, and spaces
                const regex = /^[a-zA-Z0-9\s]$/;

                // Allow backspace, delete, arrow keys, and other common navigation keys
                const allowedKeys = [
                  "Backspace",
                  "Delete",
                  "ArrowLeft",
                  "ArrowRight",
                  "ArrowUp",
                  "ArrowDown",
                  "Tab",
                ];

                // Prevent default action if the key is not allowed
                if (!regex.test(e.key) && !allowedKeys.includes(e.key)) {
                  e.preventDefault();
                }
              }}
              disabled={
                userData.status === "ask_for_resubmit" &&
                !correctionData?.hasOwnProperty("branch_name")
              }
              text={
                (userData.status === "ask_for_resubmit" &&
                  correctionData?.branch_name?.remark && (
                    <>
                      <span style={{ color: "red" }}>
                        {correctionData.branch_name.remark}
                      </span>
                    </>
                  )) ||
                (userData.status == "ongoing" && !userData.branch_name && (
                  <>
                    <span style={{ color: "red" }}>Select Branch Name</span>
                  </>
                ))
              }
            />
          </div>
        </div>
        {/* ----------------------------------------- */}
        <div className="bd-6 mb-2">
          <div>
            <label className="bank-label">
              Upload Cancelled Cheque / Passbook
            </label>
          </div>
           <div className="bd-1-input cancelled-cheque w-100">
            <div className="mb-1">
              <CFormInput
                className="borderchange"
                name="canceledCheck"
                onChange={handleCancelledChequeChange}
                type="file"
                id="formFile"
                accept="image/jpeg, image/png, application/pdf"
                text={
                  <>
                    {/* <p style={{fontWeight:"300", fontSize:"13px"}}> Only PNG, JPG, JPEG, PDF files are allowed.</p> */}
                    <br />
                    {
                      // userData.status === "ask_for_resubmit" &&
                      // Object.keys(correctionData).some(
                      //   (key) =>
                      //     key.startsWith("registration_doc_") &&
                      //     correctionData[key].label ===
                      //       "cancelled_passbook_cheque_upload"
                      // )
                      userData.status === "ask_for_resubmit" &&
                      correctionData?.hasOwnProperty(
                        "cancelled_passbook_cheque_upload"
                      ) ? (
                        <span style={{ color: "red" }}>
                          {
                            correctionData?.cancelled_passbook_cheque_upload
                              ?.remark
                          }
                        </span>
                      ) : userData.status === "ongoing" &&
                        !userDocuments?.cancelled_passbook_cheque_upload ? (
                        <>
                          <br />
                          <span style={{ color: "red" }}>
                            Please upload Cancelled Cheque
                          </span>
                        </>
                      ) : null
                    }
                  </>
                }
                disabled={
                  isUploaded?.cancelled_passbook_cheque_upload ||
                  userDocuments?.cancelled_passbook_cheque_upload
                }
              />
             
            </div>
            
            <div className="d-flex gap-2">
              <div>
                <button
                  className="btn btn-primary borderBttn"
                  style={{
                    backgroundColor: "#726fb7",
                  }}
                  onClick={handleCancelledCheckUpload}
                  disabled={isDisabledButton?.cancelled_passbook_cheque_upload}
                >
                  {isUploaded?.cancelled_passbook_cheque_upload ||
                  userDocuments?.cancelled_passbook_cheque_upload
                    ? "Uploaded"
                    : isLoadingUpload?.cancelled_passbook_cheque_upload
                    ? "Uploading..."
                    : "Upload"}
                </button>
              </div>
              {userDocuments?.cancelled_passbook_cheque_upload && (
                <div>
                  <CButton
           
                    onClick={() =>
                      handleViewClick(
                        `${userDocuments?.cancelled_passbook_cheque_upload_link}`
                      )
                    }
                    color="success"
                    variant="outline"
                  >
                    View
                  </CButton>
                </div>
              )}
              {userDocuments?.cancelled_passbook_cheque_upload && (
                <div>
                  <CButton
                    color="success"
                    
                    variant="outline"
                    onClick={handleCheckReupload}
                    disabled={
                      userData.status === "ask_for_resubmit" &&
                      !correctionData?.hasOwnProperty(
                        "cancelled_passbook_cheque_upload"
                      )
                    }
                  >
                    Reupload
                  </CButton>
                </div>
              )}
            </div>
          </div>
          <p style={{fontWeight:"400", fontSize:"13px", color: "red"}}> *Only PNG, JPG, JPEG, PDF files are allowed.</p>
          <p className="upload-warning">* Click on Upload Button after selecting the file.</p>
        </div>
      </div>
    </div>
  );
}

export default BankDetails;
