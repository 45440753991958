import { UnAuthApiService } from "../../config/apiServices";
import { GET_ALL_E_TENDER_NOTIFICATION } from "../../config/commonRegistartionApi/commonRegistrationApi";

export const getAllEtenderWebsiteNotification = (typeId,currentPage) => {
  return UnAuthApiService.get(
    `${GET_ALL_E_TENDER_NOTIFICATION}?type_id=${typeId}&page=${currentPage}`
  );
};

export const getAllEtenderWebsiteNotificationBySearch = (payload = {}) => {
  let url = `${GET_ALL_E_TENDER_NOTIFICATION}?`;
  for (let i in payload) {
    url += `${[i]}=${payload[i]}&`;
  }
  return UnAuthApiService.get(url, payload);
};
